<template>
    <div style=" background-color: white;height: 100vh;display: flex;flex-direction: row;justify-content: center;align-items: center;">

        <div style="width: 5%;">
            <img src="../../assets/images/loading.gif"  style="width: 100%" />
        </div>
    </div>
</template>
<script>
    import {redirectUri} from "../../const/redirectUri";
    import {redirect2LoginPage} from "../../const/redirectUri";
    import {doReSetStorage} from "../../api/auth";

    export default {
        beforeMount() {
            let token = this.$route.query.token;
            if (token!=null && token!=''){
              localStorage.setItem('token',token)
               let onAuhSuccess = () => {this.$router.push({ name: "botIndex"})};
               let onAuhException = () => {this.$message({  type: 'error',   message: 'token校验异常,请尝试退出重新登录',  duration:2000   });};
               doReSetStorage(onAuhSuccess,onAuhException,token);
            }
            else {
                redirect2LoginPage();
            }
        }
    }
</script>