<template>
    <div></div>
</template>
<script>
    import {redirectUri} from "../../const/redirectUri";

    export default {
        beforeCreate() {

            console.log('currentEditor.env',process.env);
            // console.log('currentEditor.env.CONFIG_ENV',process.env.VUE_APP_CONFIG_ENV);
          let uid = localStorage.getItem('_uid')
          let mainId = localStorage.getItem('_mainId')
          if (!uid || !mainId){
            let redirect = window.location.origin + '/#/auth';
            console.log('redirect:', redirect)
            window.location = redirectUri[process.env.VUE_APP_CONFIG_ENV].sso + '?redirect_uri=' + encodeURIComponent(redirect)
            return
          }
            this.FetchGet(this.requestUrl.auth.logged)
                .then(() => {
                    this.$router.push({
                        name: "botIndex",
                    })
                })
                .catch(() => {
                    let redirect = window.location.origin + '/#/auth';
                    console.log('redirect:', redirect)
                    window.location = redirectUri[process.env.VUE_APP_CONFIG_ENV].sso + '?redirect_uri=' + encodeURIComponent(redirect)
                });
        }
    }
</script>